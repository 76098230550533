<template>
  <div class="input-container">
    <div>
      <template v-if="view_total_hours">
        <b-form-input
          v-model="epm_time_allocation.hours"
          @update="
            (value) => {
              if (parseFloat(value) < 0 || value == null || value == '') {
                epm_time_allocation.hours = 0;
                patchEPMTimeAllocation();
              } else {
                epm_time_allocation.hours = value;
                patchEPMTimeAllocation();
              }
            }
          "
          size="sm"
        >
        </b-form-input>
      </template>
      <template v-else-if="!view_total_hours">
        <b-form-input
          v-model="epm_time_allocation.weekly_hour"
          @update="
            (value) => {
              if (parseFloat(value) < 0 || value == null || value == '') {
                epm_time_allocation.weekly_hour = 0;
                epm_time_allocation.hours = 0;
                patchEPMTimeAllocation();
              } else {
                epm_time_allocation.weekly_hour = value;
                patchEPMTimeAllocation();
              }
            }
          "
          size="sm"
        >
        </b-form-input>
      </template>
    </div>
    <b-form-checkbox
      class="ml-1"
      size="md"
      v-model="epm_time_allocation.counts_towards_credits"
      @input="patchEPMTimeAllocation()"
    ></b-form-checkbox>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { generateUniqueId, toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "InputEPMTimeAllocation",
  components: {},
  props: {
    EPMTimeAllocation: {
      default: function () {
        return {
          id: generateUniqueId(),
          egress_profile_matter: null,
          time_allocation: null,
          counts_towards_credits: false,
          attendance_requirement: 0,
          hours: 0,
          weekly_hour: 0,
        };
      },
    },
    TimeAllocation: {
      type: Object,
      required: true,
    },
    Matter: {
      type: Object,
      required: true,
    },
    view_total_hours: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      epm_time_allocation: { ...this.EPMTimeAllocation },
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
    }),
  },
  methods: {
    patchEPMTimeAllocation() {
      if (this.timeout) clearTimeout(this.timeout);
      if (
        !this.view_total_hours &&
        this.Matter &&
        this.Matter.weeks > 0 &&
        this.epm_time_allocation.weekly_hour >= 0
      ) {
        this.epm_time_allocation.hours = Math.round(
          parseFloat(this.epm_time_allocation.weekly_hour) *
            parseFloat(this.Matter.weeks)
        );
      } else if (!this.view_total_hours) return;
      if (
        parseFloat(this.epm_time_allocation.hours) < 0 ||
        this.epm_time_allocation.hours == null ||
        (this.epm_time_allocation.hours == "" && this.view_total_hours)
      )
        return;
      else if (this.epm_time_allocation.hours >= 0) {
        this.timeout = setTimeout(() => {
          this.epm_time_allocation.egress_profile_matter = this.Matter.id;
          this.epm_time_allocation.time_allocation = this.TimeAllocation.id;
          if (isNaN(this.epm_time_allocation.id))
            this.createEPMTimeAllocation();
          else this.updateEPMTimeAllocation();
        }, 500);
      }
    },
    createEPMTimeAllocation() {
      this.epm_time_allocation.counts_towards_credits =
        this.TimeAllocation.counts_towards_credits;
      this.epm_time_allocation.attendance_requirement =
        this.TimeAllocation.attendance_requirement;
      this.$store
        .dispatch(
          names.POST_EP_MATTER_TIME_ALLOCATION,
          this.epm_time_allocation
        )
        .then((response) => {
          this.epm_time_allocation = response;
          if (
            this.institution &&
            this.institution.internal_use_id == "ciisa_uss"
          )
            this.$restful.Get(
              `/mesh/update_ipss_egress_profile_matter_evaluatee_assessments/?egress_profile_matter=${this.Matter.id}`
            );
          if (this.Matter && this.Matter.weeks > 0)
            this.epm_time_allocation.weekly_hour = parseFloat(
              (response.hours / parseFloat(this.Matter.weeks)).toFixed(2)
            );
          else this.epm_time_allocation.weekly_hour = 0;
          toast("Cambio realizado");
          this.$emit("changed_hour");
        });
    },
    updateEPMTimeAllocation() {
      this.$store
        .dispatch(
          names.UPDATE_EP_MATTER_TIME_ALLOCATION,
          this.epm_time_allocation
        )
        .then((response) => {
          this.epm_time_allocation = response;
          if (
            this.institution &&
            this.institution.internal_use_id == "ciisa_uss"
          )
            this.$restful.Get(
              `/mesh/update_ipss_egress_profile_matter_evaluatee_assessments/?egress_profile_matter=${this.Matter.id}`
            );
          if (this.Matter && this.Matter.weeks > 0)
            this.epm_time_allocation.weekly_hour = parseFloat(
              (response.hours / parseFloat(this.Matter.weeks)).toFixed(2)
            );
          else this.epm_time_allocation.weekly_hour = 0;
          toast("Cambio realizado");
          this.$emit("changed_hour");
        });
    },
  },
  mounted() {},
  // watch: {
  //   "epm_time_allocation.hours": function (n, o) {
  //     if (parseFloat(n) < 0 || n == null || n == "" || n == o) return;
  //     else if (n >= 0) this.patchEPMTimeAllocation();
  //   },
  //   "epm_time_allocation.counts_towards_credits": function () {
  //     this.patchEPMTimeAllocation();
  //   },
  // },
  created() {
    const epm_time_allocation = this.ep_matter_time_allocations.find(
      (x) =>
        x.time_allocation == this.TimeAllocation.id &&
        x.egress_profile_matter == this.Matter.id
    );
    if (epm_time_allocation && this.Matter) {
      this.epm_time_allocation = epm_time_allocation;
      if (this.Matter.weeks > 0) {
        this.epm_time_allocation.weekly_hour = parseFloat(
          (epm_time_allocation.hours / parseFloat(this.Matter.weeks)).toFixed(2)
        );
      } else this.epm_time_allocation.weekly_hour = 0;
    }
  },
};
</script>

<style scoped>
input {
  padding: 1px 1px 1px 5px;
  height: 30px;
  max-width: 40px;
  font-size: var(--secondary-font-size);
}
.input-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
</style>